body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.center-button{
  text-align: center;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.Red {
  color: red;
}
.Green {
  color: green;
}

.right {
  text-align: right;
}
.center {
  text-align: center;
}

.details {
  font-size: 0.9rem;
}

.btn-spacey {
  margin-right: 5px;
}

.cover {
  min-height: 600px;
  align-items: center;
  display: flex;
}

@media (min-width: 992px) {
  .modal-xl {
    max-width: 75% !important;
  }
}
.nav-link {
  cursor: pointer;
}
.navbar-text {
  margin-right: 1em;
}
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

pre {
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.scroll-div {
  max-height: 20em;
  overflow-y: auto;
}

.one-em-padding {
  padding: 1em;
}

.one-em-margin {
  margin-left: 1em;
  margin-right: 1em;
}

.login-logo {
  height: 300px;
}

.brand-logo {
  height: 35px;
  margin-right: 1em;
}

